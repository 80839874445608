import dayjs from 'dayjs';
import { WorktimesType } from '../@types/calendar';
export var splitIntervalIntoHours = function (_a) {
    var startDate = _a.startDate, endDate = _a.endDate, title = _a.title, type = _a.type;
    if (!startDate || !endDate)
        return undefined;
    var start = dayjs(new Date(startDate));
    var end = dayjs(new Date(endDate));
    var differenceHour = end.diff(start, 'hour');
    var interval = [];
    if (differenceHour !== 0) {
        interval = [
            {
                title: title,
                startDate: startDate,
                endDate: dayjs(startDate).add(1, 'hour').toDate(),
                type: type || WorktimesType.WORK,
            },
        ];
        for (var i = 1; i < differenceHour; i += 1) {
            interval.push({
                title: title,
                startDate: start.add(i, 'hour').toDate(),
                endDate: start.add(i + 1, 'hour').toDate(),
                type: type || WorktimesType.WORK,
            });
        }
        var isSameEnding = dayjs(interval[interval.length - 1].endDate).isSame(end);
        if (!isSameEnding) {
            var lastEndDateInterval = interval[interval.length - 1].endDate;
            interval.push({
                title: title,
                startDate: lastEndDateInterval,
                endDate: end.toDate(),
                type: type || WorktimesType.WORK,
            });
        }
    }
    else {
        interval.push({
            title: title,
            startDate: startDate,
            endDate: endDate,
            type: type || WorktimesType.WORK,
        });
    }
    return interval;
};
